@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }   
}

@mixin desktop {
    @media (min-width: 1280px) {
        @content;
    }
}
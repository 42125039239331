@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/fonts' as *;


.recipe {
    // display: inline;
    font-size: 18px;


    &__top {
        margin-bottom: 3px;
        margin-top: 10px;
    }

    &__nutfree {
        float: right;
        height: 70px;
    }

    &__bottom {
        margin-top: 3px;
    }

    &__subtitle {
        margin-top: 30px;
    }

    @include tablet {
        padding: 16px 50px;
        font-size: 24px;
    }

    @include desktop {
        font-size: 29px;
    }
}

.ingredients {
    // display: flex;
    // flex-wrap: wrap;

    &__indiv {
        height: 36px;
        border: 2px solid $DarkBrown;
        border-radius: 50%;
        margin: 2px 3px;
    }

    @include tablet {
        padding: 0px 70px;

        &__indiv {
            height: 49px;
        }
    }

    @include desktop {

        &__indiv {
            height: 62px;
        }
    }
}

.butter {
    padding: 8px 1px;
}
.white {
    padding: 8px 9px;
}
.brown {
    padding: 8px 4px;
}
.vanilla {
    padding: 9px 0px;
    height: 36px;
    width: 36px;

    @include tablet {
        height: 49px;
        width: 52px;
    }

    @include desktop {
        height: 62px;
        width: 67px;
    }
}
.eggs {
    padding: 8px 5px;
}
.flour {
    padding: 8px 8px;
}
.bakingsoda {
    padding: 8px 5px;
}
.salt {
    padding: 8px 8px;
}
.choc {
    padding: 8px 2px;
}
.whitechoc {
    padding: 8px 2px;
}
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.buy {
    // height: 1000px;
    margin: 189px 16px 16px 16px;
    

    &__subtitle {
        border-bottom: 2px solid $DarkBrown;
        margin-top: 20px;
        padding-bottom: 10px;
    }

    &__map {
        width: 100%;
        height: 300px;
    }

    @include tablet {
        margin: 214px 55px 16px 55px;

        &__subtitle {

            &--online {
                margin-top: 60px;
            }
        }
        &__description {
            font-size: 24px;
            padding: 16px 50px 0px 50px;
        }

        &__map {
            padding: 0px 50px;
        }
    }

    @include desktop {
        margin: 229px 229px 16px 229px;

        &__description {
            font-size: 29px;
        }
    }
}
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/fonts' as *;

.ef {
    display: flex;
    flex-direction: column;
    margin: 16px 16px 8px 16px;
    align-items: center;
    border-top: 2px solid #3C190B;

    &__logo {
        height: 100px;
    }

    &__top {
        display: flex;
        align-items: center;
    }

    &__description {

        &--title {
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 20px;
        }

        &--text {
            margin-top: 5px;
            font-size: 18px;
        }
    }

    @include tablet {
        margin: 32px 55px;
        padding: 21px 70px;

        &__logo {
            height: 160px;
            padding: 0px 17px;
        }

        &__description {

            &--title {
                font-size: 30px;
            }

            &--text {
                font-size: 25px;
                margin-bottom: 10px;
            }
        }
    }

    @include desktop {
        margin: 32px 229px;

        &__description {
            &--title {
                font-size: 32px;
            }

            &--text {
                font-size: 28px;
            }
        }
    }
}
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/fonts' as *;

.about {
    margin: 179px 16px 16px 16px;

    &__subtitle {
        padding-bottom: 8px;
        border-bottom: 2px solid $DarkBrown;
    }

    @include tablet {
        margin: 226px 55px 16px 55px;
    }

    @include desktop {
        margin: 229px 229px 16px 229px;
    }
}

.mobile-break {

    @include tablet {
        display: none;
    }
}
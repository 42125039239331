@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.chef {
    margin-top: 40px;

    &__title {
        border-bottom: 2px solid $DarkBrown;
        padding-bottom: 10px;
    }

    &__description {
        font-size: 18px;
        overflow-x: visible !important;

        &--image {
            height: 136px;
            float: left;
            border-radius: 10px;
            margin: 0px 5px 3px 0px;
        }
    }

    @include tablet {
        margin-top: -7px;
        padding: 16px 50px;        
        
        &__description {
            padding: 16px 50px;
            font-size: 24px;

            &--image {
                height: 176px;
            }
        }
    }

    @include desktop {

        &__description {
            font-size: 29px;

            &--image {
                height: 227px;
            }
        }
    }
}
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/fonts' as *;

.hero {
    margin-top: 103px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    // &__top {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    // }

    &__logo {
        height: 120px;
        width: 120px;
        position: relative;
        left: 92px;
        border-radius: 50%;
    }

    &__flour {
        width: 250px;
    }

    &__title {
        font-size: 70px;
        color: #3C190B;
        font-weight: bold;
        margin: 8px 0px 6px 0px;
        line-height: 62px;
        position: relative;
    }

    &__cookies {
        width: 179px;
        height: 173px;
        padding-bottom: 10px;
    }

    &__description {
        font-size: 35px;
        font-weight: bold;
        margin: 10px 0px 11px 0px;
        color: #3C190B;
        line-height: 30px;

        &--info {
            font-size: 25px;
            color: #3C190B;
            margin: 6px 0px 13px 0px;
            line-height: 25px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            &--heart {
                padding-left: 5px;
                height: 19px;
            }
        }
    }

    @include tablet {
        margin: 100px 56px 30px 56px;
        padding: 0px;

        &__top {
            display: flex;
            flex-direction: row;

            &--left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-right: 40px;
            }
        }

        &__description {

            &--info {
                font-size: 34px;
            }
        }

        &__title {
            font-size: 88px;
        }

        &__cookies {
            width: 300px;
            height: 300px;
        }
    }

    @include desktop {
        margin: 156px 56px 30px 56px;
    }
}
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.header {
    background-image: url('../../assets/images/1x/1x/Asset\ 5.png');
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: 277px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: -2px;

    &__mobile {
        color: white;
        padding: 12px;
        height: 25px;
        width: 25px;
        position: fixed;
    }

    &__logo {
        height: 76px;
        width: 76px;
        position: fixed;
        top: 10px;
        border-radius: 50%;
        z-index: 5;
        right: 15px;
        box-shadow: 2px 2px 1px #DEC7AD;
    }

    &__left {
        //     color: #351508;
        //     font-weight: bold;
        //     padding-top: 12px;
        //     font-size: 25px;
        //     position: fixed;
        //     // right: 10px;
        //     left: 50%; /* Position at 50% from the left */
        // transform: translateX(-50%);
        display: none;


    }

    &__right {
        display: none
    }


    @include tablet {

        background-image: url('../../assets/images/1x/1x/Asset\ 6.png');
        height: 219px;
        font-size: 32px;
        // justify-content: end;
        position: fixed;

        &__left {
            display: flex;
            padding: 10px 30px 0px 30px;
            text-decoration: none;
            color: white;
            left: 20px;
            // right: 10px;
            position: relative;
            // justify-self: left;

            &--logo {
                height: 55px;
                border-radius: 50%;
                // box-shadow: 2px 2px 1px white;
            }
        }

        &__right {
            display: flex;
            // width: 50%;
            width: 32%;
            justify-content: space-evenly;
            padding-top: 14px;
            position: relative;
            // right: 30px;

            &--buy {
                color: white;
                text-decoration: none;
                color: $LightBrown;

            }

            &--about {
                color: white;
                text-decoration: none;
                color: $LightBrown;

            }

            &--auth {
                display: none;
                // display: flex;

                &--login {
                    padding-right: 20px;
                    border-right: 2px solid white;
                    height: max-content;
                    text-decoration: none;
                    color: white;
                }

                &--signup {
                    padding-left: 20px;
                    text-decoration: none;
                    color: white;
                }
            }
        }
    }

}

.mobile-header {
    display: flex;
    justify-content: space-between;

    &__list {
        position: fixed;
        left: 13px;
        top: 23px;
        color: $LightBrown;
        width: 36px;
        height: 42px;
        z-index: 4;
    }

    @include tablet {
        display: none;
    }
}

.menu {
    display: none;
    flex-direction: column;
    background-color: black;
    position: fixed;
    top: 45px;
    width: 100%;
    z-index: 3;

    &__link {
        text-decoration: none;
        color: white;
        padding: 16px 0px;
        border-bottom: 1px white solid;

        &:hover {
            background-color: grey;
        }
    }
}

.open {
    display: flex;
}